<template>
  <div>
    <headers :key="itemKey" />
    <div class="head">
      <img src="@/assets/images/schoolBj.png" />
      <div class="title">培训学院</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item  to="/school/index">培训学院</el-breadcrumb-item>
        <el-breadcrumb-item v-if="classifyId">{{classifyName||'全部'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <!-- <div class="leftBox">
        <div class="menuBox" v-for="(item, index) in menuList" :key="index">
          <div class="menuItem" @click="actMenu(index)">
            <div class="menuItemL" :style="act == index ? 'color:#00D8C3' : ''">
              {{ item.name }}
            </div>
            <div class="menuItemR"></div>
          </div>
        </div>
      </div> -->
      <div class="rightBox">
        <!-- <search @search="searchFun" /> -->

        <div class="tabBox">
          <div
            @click="tabChange(-1)"
            :class="tabAct == -1 ? 'tabItemAct' : 'tabItem'"
          >
            全部
          </div>
          <div
            v-for="(item, index) in headTabList"
            :key="index"
            @click="tabChange(index)"
            :class="tabAct == index ? 'tabItemAct' : 'tabItem'"
          >
            {{ item.name }}专属课程
          </div>
          <!-- <div
            @click="tabChange(2)"
            :class="tabAct == 2 ? 'tabItemAct' : 'tabItem'"
          >
            L2专属课程
          </div>
          <div
            @click="tabChange(3)"
            :class="tabAct == 3 ? 'tabItemAct' : 'tabItem'"
          >
            L3专属课程
          </div> -->
        </div>
        <template v-if="list.length">
          <div class="card">
            <div
              class="cardItem"
              v-for="(item, index) in list"
              :key="index"
              @click="toDetails(item)"
            >
              <div class="imgBox">
                <img class="hoverTransform" :src="item.mainImage" />
              </div>

              <div class="title textOverflowTwo">
                {{ item.name }} 
              </div> 

              <div class="btnBox"> 
                <div
                  class="btnBoxL"
                  v-if="item.equityClassifyNames.length==1"
                  :style="'background:' + backGround[item.equityClassifyNames[0]]+';color:'+colorEnum[item.equityClassifyNames[0]]"
                >
                  <span v-for="(items,indexs) in item.equityClassifyNames" :key="indexs">{{items}}</span>专属课程
                </div>
                <div
                  v-else
                  class="btnBoxL"
                  style="background:rgba(0, 216, 195, 0.1);color:#00D8C3;"
                >
                  <span v-for="(items,indexs) in item.equityClassifyNames" :key="indexs"><span v-if="indexs!=0">/</span>{{items}}</span>可查看
                </div>
                <div class="btnBoxR">浏览：{{ item.studyNum }}</div>
              </div>
            </div>
          </div>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages.total"
              :page-size="pages.size"
              :current-page="pages.page"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </template>
        <None v-else />
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { videoClassifyList, videoEquityList, videoList } from "@/api/lx";

export default {
  name: "schoolIndex",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      backGround: {
        L1: "rgba(0, 216, 195, 0.1)",
        L2: "rgba(240, 193, 11, 0.1)",
        L3: "rgba(71, 177, 226, 0.1)",
        L4: "rgba(147, 93, 228, 0.1)",
      },
      colorEnum: {
        L1: "#00D8C3",
        L2: "#F0C10B",
        L3: "#47B1E2",
        L4: "#935DE4",
      },
      itemKey:Math.random(),
      act: 0,
      tabAct: -1,
      menuList: [],
      headTabList: [],
      searchValue: "",
      list: [],
      pages: {
        page: 1,
        total: 0,
        size: 12,
      },
      classifyId:'',
      classifyName:''
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.classifyId = to.query.id;
    this.getVideoEquityList();
    this.itemKey = Math.random();
    this.getVideoClassifyList();
    next();
  },
  created() {
    this.classifyId = this.$route.query.id;
    this.getVideoEquityList();
    this.getVideoClassifyList();
  },
  mounted() {},
  methods: {
    getVideoClassifyList() {
      videoClassifyList({}).then((res) => {
        let obj = {
          id:'',
          name:'全部培训课程'
        }
        this.menuList = res.data;
        this.menuList.unshift(obj)
        let arr = this.menuList.filter((ele)=>ele.id == this.classifyId)
        this.classifyName = arr[0].name
        console.log('131',arr)
      });
    },
    getVideoEquityList() {
      videoEquityList({}).then((res) => {
        this.headTabList = res.data;
        this.getList();
      });
    },

    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getList();
    },
    currentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    getList() {
      videoList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
        classifyId: this.classifyId,
        equityClassifyId:
          this.tabAct != -1 ? this.headTabList[this.tabAct].id : null,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    actMenu(val) {
      this.pages.page = 1;
      this.act = val;
      this.getList();
    },
    tabChange(val) {
      this.pages.page = 1;
      this.tabAct = val;
      this.getList();
    },
    toDetails(item) {
      this.$router.push({
        path: "/school/details",
        query: { id: item.id,classifyName:this.classifyName,classifyId:this.classifyId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 100;
    text-align: center;
    margin-top: 61px;
  }
  .title2 {
    width: 401px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    line-height: 28px;
    text-align: right;
    font-style: normal;
    position: relative;
    z-index: 100;
    text-align: center;
    margin: 0 auto;
    margin-top: 35px;
    line-height: 30px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  margin-top: 0px;
}
.leftBox {
  width: 477px;
  .menuBox {
    width: 393px;
    margin-left: 45px;
    padding: 15px 30px 15px 60px;
    box-sizing: border-box;
    border-top: 1px solid #e6edf0;
    cursor: pointer;
    .menuItem {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      color: #00161a;
    }
    .menuCellBox {
      border-top: 1px solid #e6edf0;
      padding-top: 15px;
      box-sizing: border-box;
      margin-top: 15px;
      :first-child {
        margin-top: 0 !important;
      }
      .menuCellItem {
        font-size: 16px;
        color: #00161a;
        line-height: 22px;
        margin-top: 15px;
      }
    }
  }
}
.rightBox {
  width: 100%;
  margin-top: 0px;
  box-sizing: border-box;
}
.searchBox {
    margin-top: 54px;
  }
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .cardItem {
    width: 330px;
    margin-left: 45px;
    margin-bottom: 26px;
    box-sizing: border-box;
    cursor: pointer;
    .imgBox {
      width: 330px;
      height: 186px;
      // border: 1px dashed #e6edf0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
      margin-top: 15px;
      line-height: 25px;
      height: 50px;
    }
    .titleDetails {
      font-weight: 400;
      font-size: 14px;
      color: #525f66;
      text-align: left;
      margin-top: 10px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .btnBoxR {
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
      }
      .btnBoxL {
        // width: 75px;
        padding: 0 7px;
        height: 25px;
        background: #935de4;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 25px;
      }
    }
  }
}
.page {
  margin-left: 710px;
  margin-bottom: 55px;
}
.tabBox {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 45px;
  cursor: pointer;
  .tabItem {
    padding: 0 18px;
    height: 32px;
    line-height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #525f66;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #e6edf0;
    margin-right: 20px;
  }
  .tabItemAct {
    padding: 0 18px;
    height: 32px;
    line-height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: #00d8c3;
    border-radius: 16px;
    border: 1px solid #00d8c3;
    margin-right: 20px;
  }
}
</style>